import { useCallback, useMemo, useSyncExternalStore } from 'react';
import promotionStore, { Promotion } from 'store/promotionStore';

const usePromotion = () => {
  const { myPromotions, selectedIndex } = useSyncExternalStore(
    promotionStore.subscribe,
    promotionStore.getState
  );

  const currentPromotion = useMemo(() => {
    if (myPromotions && selectedIndex !== undefined) {
      return myPromotions[selectedIndex];
    }
  }, [myPromotions, selectedIndex]);

  const setCurrentPromotion = useCallback((promotion?: Promotion) => {
    promotionStore.setCurrentPromotion(promotion);
  }, []);

  return { currentPromotion, myPromotions, setCurrentPromotion };
};

export default usePromotion;
