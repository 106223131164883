import ua from './uaParser';
import tmapWrapper from './wrappers/TMapWrapper';

export const copyClipboard = (str: string) => {
  const value = str;
  if (ua.isInApp) {
    tmapWrapper.copyClipboard('', value);
  } else {
    navigator.clipboard.writeText(value);
  }
  return true;
};
