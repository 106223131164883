import { WEB_HOST } from 'constant/Path';
import { PATH_PREFIX, Paths } from 'constant/RoutePath';
import { StorageKey } from 'constant/Storage';
import useOnce from 'hooks/useOnce';
import pmStore from 'store/pmStore';
import promotionStore from 'store/promotionStore';
import userInfoStore from 'store/userInfoStore';
import { ECompanyType, EVehicleType } from 'types/App';
import { EUserStep } from 'types/api/User';
import { sendAFOpenedFromMkt, sendAFOpenedFromPushNotification } from 'utils/appsFlyer';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import useAdditionalLocationSearch from './useAdditionalLocationSearch';
import useMovePage from './useMovePage';
import usePmPageLaunch from './usePmPageLaunch';
import { usePmUsingStatus } from './usePmUsingStatus';
import useStorageStore from './useStorageStore';

const useTmapLinkRedirect = (startInitAction?: boolean) => {
  const [payTripOrderId, setPayTripOrderId] = useStorageStore(StorageKey.VISIT_PAY_RESULT);
  const { currentQuery } = useAdditionalLocationSearch();
  const { userKey } = usePmPageLaunch();
  const { movePage } = useMovePage();
  const { isUnpaid } = usePmUsingStatus();

  useOnce(startInitAction && currentQuery?.pageid, () => {
    // https://tmobi.atlassian.net/browse/TMAPPM-434
    // tmap://kickboard?pageid=scooter
    const pageid = (currentQuery.pageid || '') as string;
    const type = pageid.toUpperCase();

    if (Object.values(EVehicleType).includes(type as EVehicleType)) {
      pmStore.setActiveType(type as EVehicleType);
      return;
    }

    if (Object.values(ECompanyType).includes(type as ECompanyType)) {
      pmStore.setActiveType(EVehicleType.ALL);
      pmStore.setActiveCompany(type as ECompanyType);
      return;
    }
  });

  useOnce(startInitAction && (currentQuery?.extra || payTripOrderId) && !isUnpaid, () => {
    // tmap://kickboard?extra=trip_order_end_id_[TRIP_ORDER_ID]
    const fromPushNotificationTripId = Number(
      `${currentQuery?.extra || ''}`.replace('trip_order_end_id_', '')
    );
    const tripOrderId = fromPushNotificationTripId || Number(payTripOrderId || -1);

    if (!isNaN(tripOrderId) && tripOrderId > -1) {
      userInfoStore.setPmStatus({
        step: EUserStep.WAIT_RETURN,
        tripInfo: { tripOrderId },
      });

      movePage(Paths.PmReturnPage);
      fromPushNotificationTripId && sendAFOpenedFromPushNotification(fromPushNotificationTripId);
      setPayTripOrderId('');
    }
  });

  useOnce(startInitAction && currentQuery?.extra, () => {
    // tmap://kickboard?extra=mkt_channel_ABC
    const mktPrefix = 'mkt_channel_';
    const fromMarketingChannel = (currentQuery?.extra || '').includes(mktPrefix);

    if (fromMarketingChannel) {
      const channelName = `${currentQuery?.extra || ''}`.replace(mktPrefix, '');
      sendAFOpenedFromMkt(channelName, userKey);

      return;
    }

    // `tmap://kickboard?extra=${encodeURIComponent('landing_/guide?type=return&anchor=RETURN_WARNING')}`
    const landingPrefix = 'landing_';
    const fromLandingPrefix = (currentQuery?.extra || '').includes(landingPrefix);
    const landingTarget = `${currentQuery?.extra || ''}`.replace(landingPrefix, '');

    if (fromLandingPrefix && landingTarget) {
      const target = `${PATH_PREFIX}${decodeURIComponent(landingTarget)}`;

      tmapWrapper.openServiceByUrl(`${WEB_HOST}${target}`, '', 'console.log', true);
    }
  });

  /**
   * 프로모션 검증 로직
   */
  useOnce(userKey, async () => {
    // tmap://kickboard?extra=from_BAEMIN
    if (!userKey) return;
    const fromAppPrefix = 'from_';
    const promotionKey = `${currentQuery?.extra}`.replace(fromAppPrefix, '');

    // 프로모션을 링크 타고 들어왔으면 표시만 해줌
    if (currentQuery?.extra?.includes(fromAppPrefix)) {
      promotionStore.joinPromotion(promotionKey, userKey);
    }

    // 테스트 코드에서 프로모션 사용할 수 있도록 유도하는 구문
    // localStorage.setItem("WHEREGO", "userKey")
    localStorage.setItem('UNLOCK_100_PER_MAXCOUNT_3_TMAP', userKey);

    // 사용가능한 프로모션 리스트 가져오기
    const promotionList = await promotionStore.requestMyPromotions(userKey);

    if (promotionList) {
      promotionStore.setMyPromotionList(promotionList, userKey);
    }
  });
};

export default useTmapLinkRedirect;
