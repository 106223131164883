import classNames from 'classnames';
import BottomButton from 'component/BottomButton';
import BottomSheet from 'component/tmds/bottomSheet/BottomSheet';
import { useLayerContextConsumer } from 'context/LayerContext';
import usePromotion from 'hooks/usePromotion';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as IconCheck } from 'resource/images/ico_check.svg';
import styles from 'styles/components/event/PromotionBottomSheet.module.scss';

type Props = {
  onClickOverlay?: () => void;
};

const PromotionBottomSheet = ({ onClickOverlay }: Props) => {
  const { getOverlayElement } = useLayerContextConsumer();
  const { myPromotions, currentPromotion, setCurrentPromotion } = usePromotion();

  const [tempCurrentPromotion, setTempCurrentPromotion] = useState(currentPromotion);

  return createPortal(
    <div
      className={styles.wrapper}
      onClick={() => {
        onClickOverlay?.();
      }}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <BottomSheet>
          <div className={styles.title}>혜택 선택</div>
          <div className={styles.content}>
            {myPromotions?.map((promotion) => (
              <section
                className={classNames(styles.item, {
                  [styles.checked]: tempCurrentPromotion?.key === promotion.key,
                })}
                key={promotion.key}
                onClick={() => setTempCurrentPromotion(promotion)}
              >
                <div className={styles.description_wrapper}>
                  <section className={styles.sales_contents}>{promotion.saleContents}</section>
                  {promotion.title && (
                    <section className={styles.event_title}>{promotion.title} 이벤트 혜택</section>
                  )}
                </div>
                <div className={styles.check_wrapper}>
                  <IconCheck />
                </div>
              </section>
            ))}
            <section
              className={classNames(styles.item, {
                [styles.checked]: tempCurrentPromotion?.key === undefined,
              })}
              onClick={() => setTempCurrentPromotion(undefined)}
            >
              <div className={styles.description_wrapper}>
                <section className={styles.sales_contents}>할인 적용 안함</section>
              </div>
              <div className={styles.check_wrapper}>
                <IconCheck />
              </div>
            </section>
          </div>
          <div
            onClick={() => {
              setCurrentPromotion(tempCurrentPromotion);
              onClickOverlay?.();
            }}
          >
            <BottomButton gradient={false}>선택 완료</BottomButton>
          </div>
        </BottomSheet>
      </div>
    </div>,
    getOverlayElement()
  );
};

export default PromotionBottomSheet;
