import s from 'styles/pages/PmTmapInsurancePage.module.scss';

const PmTmapInsurancePage = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <h1 className={s.main_title}>TMAP 바이크 라이딩 보험은?</h1>
        <div className={s.paragraph}>
          이용자가 티맵 전기 자전거를 대여하여 사용하는 중 발생하는 사고에 대비하여 TMAP에서 무료로
          제공하는 보험입니다.
        </div>
        <div className={s.paragraph}>
          TMAP 바이크 라이딩 보험은 티맵 전기 자전거 대여 시 자동으로 가입됩니다.
        </div>
        <table>
          <thead>
            <tr>
              <th colSpan={2}> </th>
              <th>대인</th>
              <th>대물</th>
              <th>구내치료비</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                colSpan={2}
                className={s.row_title}
              >
                범위
              </td>
              <td>사고 상대방 인적 피해</td>
              <td>사고 상대방 물리적 피해</td>
              <td>본인 치료비</td>
            </tr>
            <tr>
              <td
                rowSpan={2}
                className={s.row_title}
              >
                보상
                <br />
                한도
              </td>
              <td className={s.row_title}>
                기기
                <br />
                결함
              </td>
              <td>
                4천만원
                <br />
                /1억원
              </td>
              <td>1천만원</td>
              <td>
                1백만원
                <br />
                /2백만원
              </td>
            </tr>
            <tr>
              <td className={s.row_title}>
                이용
                <br />
                자부
                <br />
                주의
              </td>
              <td>
                2천만원
                <br />
                /5천만원
              </td>
              <td>5백만원</td>
              <td>
                1백만원
                <br />
                /2백만원
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className={s.row_title}
              >
                이용자
                <br />
                부담금
              </td>
              <td>50만원</td>
              <td>50만원</td>
              <td>이용자 부주의일 경우 자기부담금 30만원 발생</td>
            </tr>
          </tbody>
        </table>
        <h1 className={s.sub_title}>가입대상</h1>
        <ul className={s.ul_margin}>
          <li>
            보험의 보상대상은 (주)티맵모빌리티, (주)피유엠피(씽씽) 의 소유로 유지, 보수, 관리되며
            사전에 보험사에 등록되어 일정 조건에 맞는 회원에게 대여되는 해당 전기 자전거를 말합니다.
          </li>
        </ul>
        <h1 className={s.sub_title}>보험기간</h1>
        <ul className={s.ul_margin}>
          <li>
            대여 절차가 끝난 시점부터 반납하는 절차가 시작되는 시점까지 전기 자전거를 이용하는 동안
            보장 받을 수 있습니다.
          </li>
          <li>
            '이용'이란 운행 및 주•정차하는 행위 등을 말하며, 운행하는 행위 및 주•정차하는 행위 등을
            제외한 그 외의 기타 관련이 없는 행위는 '이용'으로 보지 않습니다.
          </li>
        </ul>
        <h1 className={s.sub_title}>보험금 청구안내</h1>
        <ul className={s.ul_margin}>
          <li>
            보험 청구 문의 : 티맵 {'>'} 마이 탭 {'>'} 고객센터 {'>'} 1:1 문의 하기
          </li>
          <li>고객센터 운영 시간 : 평일 09:00~18:00 (점심 12:00~13:00 / 휴무 토, 일, 공휴일)</li>
        </ul>
        <h1 className={s.sub_title}>청구서류</h1>
        <ul className={s.ul_margin}>
          <li>
            보험금 청구서(보험사 양식)
            <ul>
              <li>
                사고증명서(진료비계산서, 사망진단서, 장해진단서, 입원치료확인서, 의사처방전
                (처방조제비) 등)
              </li>
              <li>
                사고증명서는 의료법 제3조(의료기관)에서 규정한 국내의 병원이나 의원 또는 국외의
                의료관련법에서 정한 의료기관에서 발급한 것이어야 합니다.
              </li>
            </ul>
          </li>
          <li>
            신분증(주민등록증이나 운전면허증 등 사진이 붙은 정부기관발행 신분증, 본인이 아닌
            경우에는 본인의 인감증명서 또는 본인서명사실확인서 포함)
          </li>
          <li>
            기타 보험금 등의 수령에 필요하여 제출하는 서류(사망보험금 지급시 피보험자의 법정상속인이
            아닌자가 청구하는 경우 법정상속인의 확인서) 필요 시 추가서류 요청이 있을 수 있습니다.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PmTmapInsurancePage;
