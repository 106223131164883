import BottomButton from 'component/BottomButton';
import { PaymentCardTitle } from 'component/PaymentCardTitle';
import PromotionTitle from 'component/PromotionTitle';
import PointSection from 'component/point/PointSection';
import { PopupLayerId } from 'constant/App';
import { Paths } from 'constant/RoutePath';
import useMovePage from 'hooks/useMovePage';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import { usePmPayment } from 'hooks/usePmPayment';
import usePmStore from 'hooks/usePmStore';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import usePopupLayer from 'hooks/usePopupLayer';
import usePromotion from 'hooks/usePromotion';
import useUserInfo from 'hooks/useUserInfo';
import { useCallback, useMemo } from 'react';
import { ReactComponent as ItemAddFill } from 'resource/images/ico_item_add_fill.svg';
import { ReactComponent as ExMarkIcon } from 'resource/images/icon-exmark.svg';
import { ReactComponent as QRScanner } from 'resource/images/icon-lineicon-qrcode.svg';
import s from 'styles/components/pmMain/PmRentButton.module.scss';
import { EPaymentStatus, TCardInfo } from 'types/App';

const CARD_INFO_ERROR = [EPaymentStatus.CARD_MAINTENANCE, EPaymentStatus.PAY_MAINTENANCE];

type TProps = {
  onClickRegister: () => void;
  onClickChangePromotion: () => void;
};

export const PmRentButton = ({ onClickRegister, onClickChangePromotion }: TProps) => {
  const { movePage } = useMovePage();
  const { isUnpaid } = usePmUsingStatus();
  const { paymentCard, fetchPaymentCard } = useUserInfo();
  const { activeItem } = usePmStore();
  const pmCardGuideLayer = usePopupLayer(PopupLayerId.PM_CARD_GUIDE_ID);
  const pmRidingGuide = usePopupLayer(PopupLayerId.PM_RIDING_GUIDE);
  const { myPromotions } = usePromotion();

  const { checkCardUpdate } = usePmPayment();

  const mainLogger = usePmLogger(LogPageId.Main);
  const pmCardLogger = usePmLogger(LogPageId.PmCard);

  const cardInfo = useMemo(() => {
    if (paymentCard?.loaded && !paymentCard.loading) {
      return paymentCard.data;
    }
  }, [paymentCard]);

  const isValidCardInfo = useMemo(() => cardInfo?.status === EPaymentStatus.OK, [cardInfo]);

  const handleClickButton = useCallback(() => {
    if (activeItem) {
      pmCardLogger.sendClickLog('tap.startride', { index: isValidCardInfo ? 1 : 0 });
    } else {
      mainLogger.sendClickLog('tap.startride', { index: isValidCardInfo ? 1 : 0 });
    }

    if (isUnpaid) {
      movePage(Paths.PmReturnPage);
      return;
    }

    if (!isValidCardInfo) {
      pmCardGuideLayer.show();
      return;
    }

    pmRidingGuide.show();
  }, [
    activeItem,
    isValidCardInfo,
    isUnpaid,
    pmCardLogger,
    mainLogger,
    movePage,
    pmCardGuideLayer,
    pmRidingGuide,
  ]);

  const handleClickPayment = useCallback(() => {
    mainLogger.sendClickLog('tap.card_change');

    checkCardUpdate().then(() => {
      fetchPaymentCard();
    });
  }, [checkCardUpdate, mainLogger, fetchPaymentCard]);

  const handleClickPromotion = useCallback(() => {
    onClickChangePromotion();
  }, [onClickChangePromotion]);

  return (
    <>
      <div className={s.payment_info}>
        {isValidCardInfo && cardInfo ? (
          <>
            <button
              className={s.card}
              onClick={handleClickPayment}
            >
              <PaymentCardTitle
                className={s.name}
                {...cardInfo}
              />
              <span className={s.text}>변경</span>
            </button>
            {myPromotions && myPromotions.length > 0 && (
              <button
                className={s.card}
                onClick={handleClickPromotion}
              >
                <PromotionTitle />
                <span className={s.text}>변경</span>
              </button>
            )}
          </>
        ) : (
          <button
            className={s.card}
            onClick={onClickRegister}
          >
            <CardInfoErrorMessage cardInfo={cardInfo} />
          </button>
        )}
      </div>
      <PointSection />
      <BottomButton
        className={s.button}
        gradient={false}
        onClick={handleClickButton}
      >
        <span className={s.icon}>
          <QRScanner />
        </span>
        대여하기
      </BottomButton>
    </>
  );
};

type TMessageProps = {
  cardInfo?: TCardInfo;
};

function CardInfoErrorMessage({ cardInfo }: TMessageProps) {
  const isErrorCardInfo = useMemo(
    () => cardInfo?.status && CARD_INFO_ERROR.includes(cardInfo.status),
    [cardInfo?.status]
  );

  const maintenanceMessage = useMemo(() => {
    if (cardInfo?.status === EPaymentStatus.CARD_MAINTENANCE) {
      return '카드 점검 중';
    }

    if (cardInfo?.status === EPaymentStatus.PAY_MAINTENANCE) {
      return '결제사 점검 중';
    }
  }, [cardInfo?.status]);

  if (isErrorCardInfo) {
    return (
      <div className={s.maintenance}>
        <div className={s.left_wrapper}>
          <ExMarkIcon />
          {maintenanceMessage}
        </div>

        <span className={s.text}>변경</span>
      </div>
    );
  }
  return (
    <div className={s.contents}>
      <ItemAddFill className={s.icon} />

      <span className={s.name}>{'신용/체크카드 등록하기'}</span>
    </div>
  );
}

export default PmRentButton;
