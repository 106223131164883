import classNames from 'classnames';
import { ReactNode } from 'react';
import { ReactComponent as IconBubbleTail } from 'resource/images/bubble_tail.svg';
import s from 'styles/components/Popover.module.scss';

type TProps = {
  text: ReactNode;
  className?: string;
};

const Popover = ({ text, className }: TProps) => {
  return (
    <div className={classNames(s.wrap, className)}>
      <div className={s.bubble_tail}>
        <IconBubbleTail />
      </div>
      <div className={s.container}>
        <span className={s.text}>{text}</span>
      </div>
    </div>
  );
};

export default Popover;
