import isPast from 'date-fns/isPast';

export const PopupLayerId = {
  NETWORK_CHANGE: 'NETWORK_CHANGE',
  DISABLE_RENT_AREA: 'DISABLE_RENT_AREA',
  REJECT_RETURN_POPUP: 'REJECT_RETURN_POPUP',
  RING_DENY: 'RING_DENY',
  PM_IMAGE_VIEWER: 'PM_IMAGE_VIEWER',
  PAUSE_GUIDE_POPUP: 'PAUSE_GUIDE_POPUP',
  PUSH_MESSAGE_POPUP: 'PUSH_MESSAGE_POPUP',
  COMPANY_SELECT_LIST: 'COMPANY_SELECT_LIST',
  LICENSE_SUCCESS: 'LICENSE_SUCCESS',
  LICENSE_FAIL: 'LICENSE_FAIL',
  LICENSE_TYPE_LAYER: 'LICENSE_TYPE',
  BACK_TMAP_MAIN: 'BACK_TMAP_MAIN',
  PM_CARD_GUIDE_ID: 'PM_CARD_GUIDE_ID',
  PM_RIDING_GUIDE: 'PM_RIDING_GUIDE',
  REMAIN_UNPAID: 'REMAIN_UNPAID',
  COMPANY_BOTTOM_LAYER: 'COMPANY_BOTTOM_LAYER',
  USER_LOCK_LAYER: 'USER_LOCK_LAYER',
  PROMOTION_LAYER: 'PROMOTION_LAYER',
};

export const LOTTIE_NAME = {
  GUIDE: 'GUIDE',
  RUNNING_DEVICE: 'RUNNING_DEVICE',
  PAYMENT: 'PAYMENT',
};

// TODO: expire date 수정
export const IS_NEW_GUIDE_EXPIRED = isPast(new Date(2023, 10, 30));
