import codeMockCases from './pages/PmCode';
import mainMockCases from './pages/PmMain';
import returnPageMockCases from './pages/PmReturnPage';
import scooterLicenseMockCases from './pages/ScooterLicense';

export const pageCaseMap = {
  ...mainMockCases,
  ...codeMockCases,
  ...returnPageMockCases,
  ...scooterLicenseMockCases,
} as const;
