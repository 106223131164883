import classNames from 'classnames';
import { ReactNode } from 'react';
import s from 'styles/components/guide/GuideItem.module.scss';

type TProps = {
  list: {
    SvgComponent?: ReactNode;
    imageSrc?: string;
    title: ReactNode;
    desc: ReactNode;
    className?: string;
  }[];
};

const GuideItem = ({ list }: TProps) => {
  return (
    <ul className={s.list}>
      {list.map((v, i) => (
        <li
          key={i}
          className={classNames(s.item, v.className)}
        >
          {v.SvgComponent && <div className={s.icon}>{v.SvgComponent}</div>}
          {v.imageSrc && (
            <img
              src={v.imageSrc}
              alt="이미지"
              className={s.icon}
            />
          )}
          <div className={s.content_wrap}>
            <span className={s.title}>{v.title}</span>
            <span className={s.desc}>{v.desc}</span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default GuideItem;
