import usePromotion from 'hooks/usePromotion';
import { useMemo } from 'react';
import { ReactComponent as IcoCoupon } from 'resource/images/ico_coupon_yellow.svg';
import styles from 'styles/components/PromotionTitle.module.scss';

const PromotionTitle = () => {
  const { currentPromotion } = usePromotion();

  const description = useMemo(() => {
    if (currentPromotion) {
      return `${currentPromotion?.saleContents}${currentPromotion?.title ? `(${currentPromotion.title})` : ''}`;
    }
    return '할인 혜택을 적용해 보세요';
  }, [currentPromotion]);

  return (
    <div className={styles.wrapper}>
      <IcoCoupon />
      <span className={styles.description}>{description}</span>
    </div>
  );
};

export default PromotionTitle;
