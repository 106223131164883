import { QueryResultRenderedFeatureCollection } from '@vsm/vsm';
import { useCallback } from 'react';
import { TVehicles } from 'types/api/Vehicle';

import useMapStore from './useMapStore';

type TMultiMarkerParams = {
  packageCode: string;
  devices: Array<TVehicles>;
  imageName: string;
};

let BASE_LAYER_ID = 100000;

export let LAYER_ID_MAP: Record<string, number> = {};
const IMAGE_RESOURCE_MAP: Record<string, boolean> = {};

const usePmMarkerLayer = () => {
  const { map } = useMapStore();

  /**
   * Query로 가져온 컬렉션에서 지도 기준으로 제일 전면에 노출 되고 있는 vehicle 요소를 선택해서 리턴하도록 함
   */
  const findVehicleByCollection = useCallback(
    (collection: QueryResultRenderedFeatureCollection) => {
      if (collection) {
        const features = [...collection.features].reverse();
        const vehicle = features.find((it) => it.properties?.vehicleId);

        return vehicle?.properties || null;
      }

      return null;
    },
    []
  );

  const removeMarker = useCallback(
    async (layerId: number) => {
      if (!map) {
        return;
      }
      if (map.hasLayer(layerId)) {
        await Promise.all([map.removeLayer(layerId), map.removeStyle(layerId)]);
      }
    },
    [map]
  );

  const deleteAllMarkerLayer = useCallback(async () => {
    const allLayerIds = Object.values(LAYER_ID_MAP);

    await Promise.all(allLayerIds.map(removeMarker));
    LAYER_ID_MAP = {};
  }, [removeMarker]);

  const clearGarbageLayer = useCallback(
    async (showLayerIds: number[]) => {
      const allLayerIds = Object.values(LAYER_ID_MAP);
      await Promise.all(
        allLayerIds
          .filter((layerId) => !showLayerIds.includes(layerId))
          .map((layerId) => removeMarker(layerId))
      );
    },
    [removeMarker]
  );

  const getGeoJsonByVehicle = useCallback<any>(
    (vehicle: TVehicles, stackId: number, grade: number) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [vehicle.lon, vehicle.lat],
        },
        properties: {
          id: vehicle.vehicleId,
          grade: grade,
          maxLevel: '21',
          minLevel: '0',
          type: '1',
          stackId: stackId,
          visibility: 'show',
          ...vehicle,
        },
      };
    },
    []
  );

  const addMultiMarker = useCallback(
    async ({ imageName, devices, packageCode }: TMultiMarkerParams, keyIndex: number) => {
      if (!map) {
        return;
      }
      const currentLayerId = LAYER_ID_MAP[imageName] || BASE_LAYER_ID++;
      const stackId = currentLayerId;
      LAYER_ID_MAP[imageName] = currentLayerId;

      const features = devices.map((device) => getGeoJsonByVehicle(device, stackId, keyIndex));

      if (map.hasLayer(currentLayerId)) {
        map.updateGeoJsonSourceData(currentLayerId, {
          type: 'FeatureCollection',
          features,
        });
      } else {
        await map.addStyle(currentLayerId, {
          [stackId]: [
            {
              id: stackId,
              condition: '',
              icon: {
                icon: `${packageCode}:${imageName}`,
                'icon-optional': true,
                'icon-allow-overlap': true,
                'offset-y': -12,
              },
            },
          ],
        });

        await map.addLayer({
          id: currentLayerId,
          name: imageName,
          map: {
            format: 'geojson',
            preventOverlap: true,
            data: {
              type: 'FeatureCollection',
              features,
            },
          },
          stacks: [
            {
              id: stackId,
              code: 'OVERLAY_MARKER',
              name: '오버레이_마커',
              geometryType: 'POINT',
              properties: {
                sortBy: 'grade',
                poi: '1',
              },
            },
          ],
        });
      }
    },
    [getGeoJsonByVehicle, map]
  );

  const registerSVGResource = useCallback(
    async (packageCode: string, params: Array<{ id: string; svgData: string }>) => {
      if (!map) {
        return;
      }

      if (IMAGE_RESOURCE_MAP[packageCode]) {
        return;
      }

      IMAGE_RESOURCE_MAP[packageCode] = true;

      try {
        await map.addImageResources(
          packageCode,
          params.map(({ id, svgData }) => ({
            id,
            image: {
              type: 'svg',
              data: svgData,
            },
          })),
          {
            pixelRatio: 9,
          }
        );
      } catch {
        IMAGE_RESOURCE_MAP[packageCode] = false;
      }
    },
    [map]
  );

  return {
    registerSVGResource,
    addMultiMarker,
    removeMarker,
    clearGarbageLayer,
    findVehicleByCollection,
    deleteAllMarkerLayer,
  };
};

export default usePmMarkerLayer;
